<template>
  <el-dialog
    :title="dataForm.id ? '编辑' : '新增'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="dataForm"
      label-width="120px"
      :rules="dataRule"
    >
      <el-form-item
        label="开始节点"
        prop="beginNodeId"
      >
        <el-select
          v-model="dataForm.beginNodeId"
          @change="getStateList"
        >
          <el-option
            v-for="(item,index) in nodeList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="状态"
        prop="stateValue"
      >
        <el-select v-model="dataForm.stateValue">
          <el-option
            v-for="(item,index) in stateList"
            :key="index"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="结束节点"
        prop="endNodeId"
      >
        <el-select
          v-model="dataForm.endNodeIdList"
          multiple
          clearable
        >
          <el-option
            v-for="(item,index) in nodeList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      users: [],
      loading: false,
      dialogVisible: false,
      dataForm: {
        id: 0,
        flowId: 0,
        beginNodeId: undefined,
        endNodeIdList: [],
        stateValue: '',
      },
      nodeList: [],
      stateList: [],
    }
  },
  computed: {
    dataRule() {
      return {
        beginNodeId: [
          { required: true, message: '开始节点不能为空。', trigger: 'blur' },
        ],
        endNodeIdList: [
          {
            type: 'array', required: true, message: '结束节点不能为空。', trigger: 'blur',
          },
        ],
        stateValue: [
          { required: true, message: '状态不能为空。', trigger: 'blur' },
        ],
      }
    },
  },
  created() {
  },
  dialogClose() {
    this.dataForm = {
      id: 0,
      flowId: 0,
      beginNodeId: undefined,
      endNodeIdList: [],
      stateValue: '',
    }
  },
  methods: {
    init(flowId, id) {
      this.dialogVisible = true
      this.dataForm.flowId = flowId
      this.getNodeList()
      if (id) {
        this.getInfo(id)
      }
    },
    // 获取信息
    getInfo(id) {
      this.$http.get(`/flow/custom/node/link/info?id=${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.dataForm = res.data
        }
      }).catch(() => {})
    },
    getNodeList() {
      this.$http.get(`/flow/custom/node/select?id=${this.dataForm.flowId}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.nodeList = res.data
        }
      }).catch(() => {})
    },
    getStateList() {
      this.$http.get(`/flow/custom/node/state/select?id=${this.dataForm.beginNodeId}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.stateList = res.data
          this.dataForm.stateValue = ''
        }
      }).catch(() => {})
    },
    dialogClose() {
      this.dataForm = {
        id: 0,
        flowId: 0,
        beginNodeId: undefined,
        endNodeId: undefined,
        stateValue: '',
      }
    },
    submitForm: debounce(function () {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$http.post(!this.dataForm.id ? '/flow/custom/node/link/create' : '/flow/custom/node/link/update', this.dataForm).then(({ data: res }) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.dialogVisible = false
                  this.loading = false
                  this.$emit('refreshDataList')
                },
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
        this.loading = false
      })
    }, 1000, { leading: true, trailing: false }),
  },
}
</script>
