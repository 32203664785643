<template>
  <el-dialog
      :title="dataForm.id ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
      width="620px"
      @close="dialogClose"
  >
    <el-form
        v-if="dialogVisible"
        ref="form"
        v-loading="loading"
        :model="dataForm"
        label-width="120px"
        :rules="dataRule"
    >
      <el-form-item
          label="名称"
          prop="name">
        <el-input v-model="dataForm.name" placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item
          label="说明"
          prop="explain">
        <el-input type="textarea" v-model="dataForm.explain" placeholder="说明"></el-input>
      </el-form-item>
      <el-form-item
          label="操作指引"
          prop="guide">
        <el-input type="textarea" v-model="dataForm.guide" placeholder="操作指引"></el-input>
      </el-form-item>
      <el-form-item
          label="排序号"
          prop="sortNo">
        <el-input type="number" v-model="dataForm.sortNo"></el-input>
      </el-form-item>
      <el-form-item
          label="处理人"
          prop="handleUserId"
      >
        <el-select v-model="dataForm.handleUserId">
          <el-option
              v-for="(item,index) in userList"
              :key="index"
              :label="item.actualName"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
          v-for="(item,index) in dataForm.stateList1"
          :key="index"
          :label="'状态' + (index+1)"
          class="value-form-item"
          :prop="'stateList1.' + index + '.value'"
          :rules="[ { required: true, message: '状态不能为空', trigger: 'change' }]"
      ><el-row>
        <el-col :span="18"><el-input v-model="item.value"></el-input></el-col>
        <el-col :span="3">
          <el-button @click="dataForm.stateList1.push({value: ''})">
            <i class="el-icon-circle-plus-outline cursor-pointer" />
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button v-if="index !== 0"
                     @click="dataForm.stateList1.splice(index,1)">
            <i class="el-icon-remove-outline cursor-pointer" />
          </el-button>
        </el-col>
      </el-row>
      </el-form-item>
    </el-form>
    <div
        slot="footer"
        class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
          type="primary"
          :disabled="loading"
          @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import { queryUserOptions } from '@/api/system/system'

export default {
  data() {
    return {
      users: [],
      loading: false,
      dialogVisible: false,
      dataForm: {
        id: 0,
        flowId: 0,
        name: '',
        explain: '',
        guide: '',
        sortNo: 0,
        handleUserId: undefined,
        stateList: [],
        stateList1: [{ value: '' }],
      },
      userList: [],
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          { required: true, message: '名称不能为空。', trigger: 'blur' },
          {
            min: 3, max: 20, message: '名称长度在3到20个字符', trigger: 'blur',
          },
        ],
        handleUserId: [
          { required: true, message: '处理人不能为空。', trigger: 'blur' },
        ],
        explain: [
          {
            min: 0, max: 100, message: '说明长度在0到100个字符', trigger: 'blur',
          },
        ],
      }
    },
  },
  created() {
    this.getUserSelect()
  },
  methods: {
    init(count, flowId, id) {
      this.dialogVisible = true
      this.dataForm.sortNo = count + 1
      this.dataForm.flowId = flowId
      if (id) {
        this.getInfo(id)
      }
    },
    getUserSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.userList = res.data.data
        }
      })
    },
    // 获取信息
    getInfo(id) {
      this.$http.get(`/flow/custom/node/info?id=${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          }
          if (res.data.stateList.length > 0) {
            this.dataForm.stateList1 = res.data.stateList.map(item => {
              const newItem = {
                value: item,
              }
              return newItem
            })
          } else {
            this.dataForm.stateList1 = [{ value: '' }]
          }
        }
      }).catch(() => {})
    },
    dialogClose() {
      this.dataForm = {
        id: 0,
        flowId: 0,
        name: '',
        explain: '',
        guide: '',
        sortNo: 0,
        handleUserId: undefined,
        stateList: [],
        stateList1: [{ value: '' }],
      }
    },
    submitForm: debounce(function () {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.dataForm.stateList = this.dataForm.stateList1.map(item => item.value)
          this.$http.post(!this.dataForm.id ? '/flow/custom/node/create' : '/flow/custom/node/update', this.dataForm).then(({ data: res }) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.dialogVisible = false
                  this.loading = false
                  this.$emit('refreshDataList')
                },
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
        this.loading = false
      })
    }, 1000, { leading: true, trailing: false }),
  },
}
</script>
