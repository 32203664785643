<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="14">
        <b-card>
          <div class="filter-wrap flex-between">
            <div class="flex-start search-filter-wrap">
              <el-input
                v-model="dataForm.searchStr"
                width="180"
                placeholder="请输入内容"
                clearable
                @clear="handleFilter"
              />
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="handleFilter"
              >
                搜索
              </el-button>
            </div>
            <div>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="addOrUpdateNodeHandle()"
              >
                新增节点
              </el-button>
            </div>
          </div>
          <div class="table-wrap">
            <el-table
              v-loading="nodeDataListLoading"
              fit
              border
              highlight-current-row
              :data="nodeDataList"
              stripe
              style="width: 100%"
            >
              <el-table-column
                prop="name"
                :show-overflow-tooltip="true"
                label="名称"
              />
              <el-table-column
                prop="stateValueStr"
                :show-overflow-tooltip="true"
                label="状态值"
              />
              <el-table-column
                prop="explain"
                :show-overflow-tooltip="true"
                label="说明"
              />
              <el-table-column
                prop="guide"
                :show-overflow-tooltip="true"
                label="操作指引"
              />
              <el-table-column
                prop="handleUserName"
                :show-overflow-tooltip="true"
                label="处理人"
              />
              <el-table-column
                label="操作"
                width="160px"
              >
                <template v-slot="scope">
                  <el-link
                    class="mr10"
                    :underline="false"
                    type="primary"
                    @click="addOrUpdateNodeHandle(scope.row.id)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>

                  <el-link
                    class="mr10"
                    :underline="false"
                    type="primary"
                    @click="deleteNodeHandle(scope.row.id)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
            <!-- 弹窗, 新增 / 修改 -->
            <EditNodeDialog
              v-if="editNodeVisible"
              ref="editNodeDialog"
              @refreshDataList="getNodeList"
            />
          </div>
        </b-card>
      </el-col>
      <el-col :span="10">
        <b-card>
          <div
            style="float: right;"
            class="filter-wrap flex-between"
          >
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="addOrUpdateLinkHandle()"
            >
              新增节点关系
            </el-button>
          </div>
          <div class="table-wrap">
            <el-table
              v-loading="linkDataListLoading"
              fit
              border
              highlight-current-row
              :data="linkDataList"
              stripe
              style="width: 100%"
            >
              <el-table-column
                prop="beginNodeName"
                :show-overflow-tooltip="true"
                label="开始节点"
              />
              <el-table-column
                prop="stateValue"
                :show-overflow-tooltip="true"
                label="过程条件"
              />
              <el-table-column
                prop="endNodeName"
                :show-overflow-tooltip="true"
                label="结束节点"
              />
              <el-table-column
                label="操作"
                width="160px"
              >
                <template v-slot="scope">
                  <el-link
                    class="mr10"
                    :underline="false"
                    type="primary"
                    @click="addOrUpdateLinkHandle(scope.row.id)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    class="mr10"
                    :underline="false"
                    type="primary"
                    @click="deleteLinkHandle(scope.row.id)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
            <!-- 弹窗, 新增 / 修改 -->
            <edit-link-dialog
              v-if="editLinkVisible"
              ref="editLinkDialog"
              @refreshDataList="getNodeLinkList"
            />
          </div>
        </b-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditNodeDialog from './node-edit.vue'
import EditLinkDialog from './node-link-edit.vue'

export default {
  components: {
    EditNodeDialog, EditLinkDialog,
  },
  data() {
    return {
      nodeDataListLoading: false,
      linkDataListLoading: false,
      editNodeVisible: false,
      editLinkVisible: false,
      nodeDataList: [],
      linkDataList: [],
      dataForm: {
        flowId: Number(this.$route.query.flowId),
        searchStr: '',
      },
      nodeCount: 0,
    }
  },
  created() {
    this.getNodeList()
  },
  methods: {
    getNodeList() {
      this.nodeDataListLoading = true
      this.linkDataListLoading = true
      this.$http.get(`/flow/custom/node/list?id=${this.dataForm.flowId}`).then(({ data: res }) => {
        this.nodeDataListLoading = false
        if (res.code !== 0) {
          this.nodeDataList = []
          this.$message.error(res.msg)
          this.linkDataListLoading = false
        } else {
          this.nodeDataList = res.data
          if (this.nodeDataList.length > 0) {
            this.nodeCount = this.nodeDataList.length
            this.getNodeLinkList()
          } else {
            this.linkDataListLoading = false
          }
        }
      }).catch(() => {
        this.nodeDataListLoading = false
        this.linkDataListLoading = false
      })
    },
    getNodeLinkList() {
      this.linkDataListLoading = true
      this.$http.get(`/flow/custom/node/link/list?id=${this.dataForm.flowId}`).then(({ data: res }) => {
        this.linkDataListLoading = false
        if (res.code !== 0) {
          this.linkDataList = []
          this.$message.error(res.msg)
        } else {
          this.linkDataList = res.data
        }
      }).catch(() => {
        this.linkDataListLoading = false
      })
    },
    handlePage(data) {
      this.dataForm.pageNo = data.page
      this.getNodeList()
    },
    addOrUpdateNodeHandle(id) {
      this.editNodeVisible = true
      this.$nextTick(() => {
        this.$refs.editNodeDialog.init(this.nodeCount, this.dataForm.flowId, id)
      })
    },
    addOrUpdateLinkHandle(id) {
      this.editLinkVisible = true
      this.$nextTick(() => {
        this.$refs.editLinkDialog.init(this.dataForm.flowId, id)
      })
    },
    handleFilter() {
      this.dataForm.pageNo = 1
      this.getNodeList()
    },
    deleteNodeHandle(id) {
      this.$confirm('确定进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.nodeDataListLoading = true
        this.$http.delete(`/flow/custom/node/delete?id=${id}`).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 500,
              onClose: () => {
                this.getNodeList()
              },
            })
          }
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    deleteLinkHandle(id) {
      this.$confirm('确定进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.linkDataListLoading = true
        this.$http.delete(`/flow/custom/node/link/delete?id=${id}`).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 500,
              onClose: () => {
                this.getNodeList()
              },
            })
          }
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    nodePage(row) {
      this.$router.push({
        path: '/customFlowNode',
        query: {
          flowId: row.id,
        },
      })
    },
    pageSizeChangeHandle(val) {
      this.dataForm.pageNo = 1
      this.dataForm.pageSize = val
      this.getList()
    },
    pageCurrentChangeHandle(val) {
      this.dataForm.pageNo = val
      this.getList()
    },
  },
}
</script>

<style>
.el-tooltip__popper{
  max-width: 20%;
}
</style>
